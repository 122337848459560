export const defaultSettings = {
	background: '#0693e3',
	color: '#ffffff',
	images: [],
	headingFontSize: 44,
	summaryFontSize: 25,
	width: 1000,
	height: 420,
	radius: 10,
	border: 0,
	borderColor: '#333',
	bgUrl: 'https://source.unsplash.com/random/1280x807?programming',
	exportExt: 'png',
	font: 'Droid Sans',
	overlay: 0.6,
};
